import BasePlugin from '../BasePlugin'
import CalculateFinancialIndicatorsCmp from './CalculateFinancialIndicatorsCmp'
import { eventBus } from '@/eventBus'

export default class CalculateIndicatorsByAllCalculationTypeIdInAdjustmentRequestCommand extends BasePlugin {
  async execute () {
    let recordId = this.context.getModel().id
    let context = this.context
    this.context.getCard().setLoading(true)
    console.log(this.context.getDashboardComponents())
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/CalculateFinancialIndicatorsByAdjustmentRequestCommand`,
      { 'adjustment_request_id': recordId },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      this.context.getCard().setLoading(false)
      let localization = {
        manual_method_not_found: 'метод ручного расчета не найден',
        calculation_error: 'ошибка в расчетах',
        argument_indicator_calculation_error: 'ошибка в расчетах одного и/или нескольких аргументов'
      }
      Object.values(response.data.plan.calculated).forEach(function (period) {
        Object.values(period.error).forEach(function (item) {
          item.error_code = localization[item.error_code]
        })
      })
      Object.values(response.data.fact.calculated).forEach(function (period) {
        Object.values(period.error).forEach(function (item) {
          item.error_code = localization[item.error_code]
        })
      })
      const h = this.context.$createElement
      this.context.$msgbox({
        title: 'Результаты расчетов показателей',
        message: h('p', { key: Math.random() }, [
          h(CalculateFinancialIndicatorsCmp, { props: { model: response.data } })
        ]),
        showCancelButton: false,
        showConfirmButton: false
      })
      eventBus.$emit('registry-card-saved')
      setTimeout(function () {
        document.getElementById('container_message_box').parentElement.parentElement.parentElement.parentElement.style.width = '840px'
      }, 500)
    }).catch(function () {
      context.getCard().setLoading(false)
    })
  }
}
